import { useEffect } from "react";
import Player from "./Player.js"
import "./TicketToRide.css"

//#CF6679
const TicketToRide = () => {

    return ( 
        <div className="ttrplayers">
            <div className="player-black">
                <Player bordercolor="rgba(30,30,30,1)"/>
            </ div>
            <div className="player-red">
                <Player bordercolor="rgba(255,69,58,1)" /> 
            </ div>
            <div className="player-blue">
                <Player bordercolor = "rgba(65,155,249,1)"/>
            </ div>
            <div className="player-green">
                <Player bordercolor = "rgba(52,199,89,1)"/>
            </ div>
            <div className="player-yellow">
                <Player bordercolor = "rgba(172,142,104,1)"/>
            </ div>
      </div>
    );
}

export default TicketToRide;