import { useEffect } from "react";
import Player from "./Player";
import "./soc.css"

//#CF6679
const Players = () => {



    return ( 
        <div className="players">
            <div className="player-white">
                <Player bordercolor="rgba(255,255,255,0.85)"/>
            </ div>
            <div className="player-red">
                <Player bordercolor="rgba(255,69,58,1)" /> 
            </ div>
            <div className="player-blue">
                <Player bordercolor = "rgba(65,155,249,1)"/>
            </ div>
            <div className="player-green">
                <Player bordercolor = "rgba(52,199,89,1)"/>
            </ div>
            <div className="player-brown">
                <Player bordercolor = "rgba(172,142,104,1)"/>
            </ div>
            <div className="player-orange">
                <Player bordercolor = "rgba(238,103,35,1)"/>
            </ div>
      </div>
    );
}

export default Players;