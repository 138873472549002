import "./Navbar.css"
const Navbar = () => {
    return ( 
        <nav className="navbar">
            <h1>NS Scorecard</h1>
            <div className="pages">
                <a href="/">Home</a>
                <a href="/soc">Settlers of Catan</a>
                <a href="/ttr">Ticket To Ride</a>
            </div>
        </nav>
    );
}

export default Navbar;