import Navbar from './Navbar';
import Home from './Home';
import SOC from './pages/SettlersOfCatan/SOC';
import TicketToRide from './pages/TicketToRide/TicketToRide';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path ="/" element= {<Home/>}/>
        <Route path="soc" element={<SOC/>}/>
        <Route path="ttr" element={<TicketToRide/>}/>
      </Routes>

    </div>
  );
}

export default App;
