import { useEffect, useState } from "react";
import data from "./ttr_routes_map.json"
import Multiselect from 'multiselect-react-dropdown';

const TTR_Routes = ({ttrRoutes, handleUpdateTTRRoutes}) => {

    const [ttr_routes, setTTRRoutes] = useState([])
    const [completed_routes, setCompletedRoutes] = useState()
    const [missedRoutes, setMissedRoutes] = useState()

    useEffect(() => {
    }, []);
    

    return ( 
        <div className="ttrroutes">
            
            <Multiselect
                placeholder="Completed Routes"
                isObject={false}
                onKeyPressFn={function noRefCheck(){}}
                onRemove={function noRefCheck(){}}
                onSearch={function noRefCheck(){}}
                onSelect={function noRefCheck(){}}
                
                options={[
                    'Boston -> Miami',
                    'Calgary -> Phoenix',
                    'Calgary -> Salt Lake City',
                    'Chicago -> New Orleans',
                    'Chicago -> Sante Fe'
                ]}
            />

            <Multiselect
            placeholder="Uncompleted Routes"
                isObject={false}
                onKeyPressFn={function noRefCheck(){}}
                onRemove={function noRefCheck(){}}
                onSearch={function noRefCheck(){}}
                onSelect={function noRefCheck(){}}
                options={[
                    'Boston -> Miami',
                    'Calgary -> Phoenix',
                    'Calgary -> Salt Lake City',
                    'Chicago -> New Orleans',
                    'Chicago -> Sante Fe'
                ]}
            />
        </div>
    );
}

export default TTR_Routes;