import { useState, useEffect, useRef } from 'react';
import Building from './Building';


const Player = (props) => {

    
    const [roads, setRoads] = useState (0)
    const [cities, setCities] = useState(0);
    const [settlements, setSettlements] = useState(0);
    const [barbarians, setBarbarians] = useState(0);
    const [metros, setMetros] = useState(0);
    const [totalScore, setTotalScore] = useState(0);
    const [merchant, setMerchant] = useState(0);
    const [longestroad, setLongestRoad] = useState(0);
    const [progresspoints, setProgressPoints] = useState(0);
    const [buildingScore, setBuildingScore] = useState(0)
    const [bonusScore, setBonusScore] = useState(0)
    const [newTotal, setNewTotal] = useState(0)
    const [bonusSpan, setbonusSpan] = useState(<span></span>)
    

    useEffect(()=>{
        let buildScore = settlements + (cities*2) + barbarians + metros + progresspoints
        let bonusScore = merchant + longestroad*2
        setBuildingScore(buildScore)
        setBonusScore(bonusScore)
        setNewTotal(buildScore + bonusScore)
        if(bonusScore !== 0){
            setbonusSpan(<span>Bonus: {bonusScore}</span>)
        }else{
            setbonusSpan(<span></span>)
        }
    }, [barbarians, cities, settlements, merchant, longestroad, metros, progresspoints]);

    const handleUpdateRoads = (mult) => {
        let newroads = roads + mult
        if (newroads >=0){
            setRoads(newroads)
        }
        
    }

    const handleUpdateSettlements = (mult) => {
        let newset = settlements + mult
        if (newset >=0){
            setSettlements(newset)
            setTotalScore(totalScore+mult)
        }       
    }

    // const handleAddSettlements = (e) => {
    //     setSettlements(settlements+1)
    //     setTotalScore(totalScore+1)
    // }

    // const handleRemoveSettlements = (e) => {
    //     if (settlements >=1){
    //         setSettlements(settlements-1)
    //         setTotalScore(totalScore-1)
    //     }
        
    // }

    const handleUpdateCities = (mult) => {
        
        if (mult > 0 && settlements !== 0){
            setSettlements(settlements-1)
            setCities(cities+1)
            setTotalScore(totalScore+1)
        } else if (mult < 0 && cities !==0){
                setCities(cities-1)
                setSettlements(settlements+1)
                setTotalScore(totalScore-1)
             
        }
    }

    // const handleAddCities = (e) => {
    //     if (settlements != 0){
    //         setSettlements(settlements-1)
    //         setCities(cities+1)
    //         setTotalScore(totalScore+1)
    //     }
    // }

    // const handleRemoveCities = (e) => {
    //     if (cities !=0){
    //         setCities(cities-1)
    //         setSettlements(settlements+1)
    //         setTotalScore(totalScore-1)
    //     }   
    // } 

    const handleUpdateBarbarian = (mult) => {
        let newbarb = barbarians + mult
        if (newbarb >=0){
            setBarbarians(newbarb)
            setTotalScore(totalScore+mult)
        }       
    }

    // const handleAddBarbarian = (e) => {
    //     if(barbarians===0){
    //         setBarbarians(barbarians+1)
    //     }
    // }

    // const handleRemoveBarbarian = (e) => {
    //     if(barbarians===1){
    //         setBarbarians(barbarians-1)
    //     }
    // }

    const handleUpdateMetro = (mult) => {
        let newmetro = metros + mult
        if (newmetro >=0 && newmetro <=3){
            setMetros(newmetro)
            setTotalScore(totalScore+mult)
        }       
    }
    const handleUpdateMerchant = (mult) => {
        let newmerchant = merchant + mult
        if (newmerchant ===0 || newmerchant === 1){
            setMerchant(newmerchant)
            setTotalScore(totalScore+mult)
        }       
    }
    const handleUpdateLongestroad = (mult) => {
        let newlr = longestroad + mult
        if (newlr === 0 || newlr === 1){
            setLongestRoad(newlr)
            setTotalScore(totalScore+mult*2)
        }       
    }
    const handleUpdateProgressPoint = (mult) => {
        let newprog = progresspoints + mult
        if (newprog >=0){
            setProgressPoints(newprog)
            setTotalScore(totalScore+mult)
        }       
    }
    const resetPlayer = () => {
        setBarbarians(0)
        setRoads(0)
        setSettlements(0)
        setCities(0)
        setMetros(0)
        setProgressPoints(0)
        setMerchant(0)
        setLongestRoad(0)
        setBonusScore(0)
        setTotalScore(0)
    }
    return ( 
        <div className="player" >
            <div className='counters' style={{backgroundColor:props.bordercolor}}>
                <Building buildingName="Roads" buildingCount={roads} handleAddBuilding={handleUpdateRoads} handleRemoveBuilding={handleUpdateRoads}/>
                <Building buildingName="Settlements" buildingCount={settlements} handleAddBuilding={handleUpdateSettlements} handleRemoveBuilding={handleUpdateSettlements}/>
                <Building buildingName="Cities" buildingCount={cities} handleAddBuilding={handleUpdateCities} handleRemoveBuilding={handleUpdateCities}/>
                <Building buildingName="Barbarians" buildingCount={barbarians} handleAddBuilding={handleUpdateBarbarian} handleRemoveBuilding={handleUpdateBarbarian}/>
                <Building buildingName="Metros" buildingCount={metros} handleAddBuilding={handleUpdateMetro} handleRemoveBuilding={handleUpdateMetro}/>
                <Building buildingName="ProgPoints" buildingCount={progresspoints} handleAddBuilding={handleUpdateProgressPoint} handleRemoveBuilding={handleUpdateProgressPoint}/>
                <Building buildingName="Merchant" buildingCount={merchant} handleAddBuilding={handleUpdateMerchant} handleRemoveBuilding={handleUpdateMerchant}/>
                <Building buildingName="Longest Road" buildingCount={longestroad} handleAddBuilding={handleUpdateLongestroad} handleRemoveBuilding={handleUpdateLongestroad}/>
            </div>
            <div className='scores'>
                <div className='breakdown'>
                    <span>Buildings: {buildingScore} </span>
                    {bonusSpan}
                    <button title='Reset' className='resetButton' onClick={() => resetPlayer()}>Reset</button>
                </div>
                
                <div className='totalScore'>
                    <textarea defaultValue='Player Name' />
                    <p>Total Score: {newTotal}</p>
                </div>
                
            </div>
        </div>
    );
}

export default Player;