
const Building = ({buildingName, buildingCount, handleAddBuilding, handleRemoveBuilding}) => {

    

    return ( 
        <div className="building" title={buildingName}>
            
            <span> {buildingName}: {buildingCount} </span> 
            <div className='buttons'>
                <button title='Minus' onClick={() => handleRemoveBuilding(-1)}>-</button>
                <button title='Plus' onClick={() => handleAddBuilding(1)}>+</button>
            </div>
        </div>
    );
}

export default Building;