import "./Home.css"
function Home() {
  return (
    <div className="Home">

      <div>
        <p className="welcome">Welcome!</p>
      </div>

    </div>
  );
}

export default Home;
