import { useState, useEffect, useRef } from 'react';
import Track from './Track';
import TTR_Routes from './TTR_Routes';


const Player = (props) => {

    
    const [singleTrack, setSingleTrack] = useState (0)
    const [twoTrack, setTwoTrack] = useState(0);
    const [threeTrack, setThreeTrack] = useState(0);
    const [fourTrack, setFourTrack] = useState(0);
    const [fiveTrack, setFiveTrack] = useState(0);
    const [sixTrack, setSixTrack] = useState(0);
    const [trackScore, setTrackScore] = useState(0);
    const [roads, setBuildingScore] = useState(0)
    const [totalScore, setTotalScore] = useState(0)
    const [newTotal, setNewTotal] = useState(0)
    const [bonusSpan, setbonusSpan] = useState(<span></span>)
    

    useEffect(()=>{
        let trackScore = singleTrack + (twoTrack*2) + (threeTrack*4) + (fourTrack*7) + (fiveTrack*10) + (sixTrack*15)

        setTrackScore(trackScore)
        setNewTotal(trackScore)

    }, [singleTrack, twoTrack, threeTrack, fourTrack, fiveTrack, sixTrack]);

    const handleUpdateSingleTrack = (mult) => {
        let newSingleTrack = singleTrack + mult
        if (newSingleTrack >=0){
            setSingleTrack(newSingleTrack)
        }  
    }

    const handleUpdateTwoTrack = (mult) => {
        let newTwoTrack = twoTrack + mult
        if (newTwoTrack >=0){
            setTwoTrack(newTwoTrack)
        }
    }

    const handleUpdateThreeTrack = (mult) => {
        let newThreeTrack = threeTrack + mult
        if (newThreeTrack >=0){
            setThreeTrack(newThreeTrack)
        }
    }

    const handleUpdateFourTrack = (mult) => {
        let newFourTrack = fourTrack + mult
        if (newFourTrack >=0){
            setFourTrack(newFourTrack)
        }
    }
    const handleUpdateFiveTrack = (mult) => {
        let newFiveTrack = fiveTrack + mult
        if (newFiveTrack >=0){
            setFiveTrack(newFiveTrack)
        }
    }
    const handleUpdateSixTrack = (mult) => {
        let newSixTrack = sixTrack + mult
        if (newSixTrack >=0){
            setSixTrack(newSixTrack)
        }
    }
    const handleTTRRoutes = () => {

    } 

    const resetPlayer = () => {
        setSingleTrack(0)
        setTwoTrack(0)
        setThreeTrack(0)
        setFourTrack(0)
        setFiveTrack(0)
        setSixTrack(0)
    }
    return ( 
        <div className="ttrplayer" >
            <div className='counters' style={{backgroundColor:props.bordercolor}}>
                <Track trackName='One Track' trackCount={singleTrack} handleUpdateTrack={handleUpdateSingleTrack}/>
                <Track trackName='Two Tracks' trackCount={twoTrack} handleUpdateTrack={handleUpdateTwoTrack}/>
                <Track trackName='Three Tracks' trackCount={threeTrack} handleUpdateTrack={handleUpdateThreeTrack}/>
                <Track trackName='Four Tracks' trackCount={fourTrack} handleUpdateTrack={handleUpdateFourTrack}/>
                <Track trackName='Five Tracks' trackCount={fiveTrack} handleUpdateTrack={handleUpdateFiveTrack}/>
                <Track trackName='Six Tracks' trackCount={sixTrack} handleUpdateTrack={handleUpdateSixTrack}/>
                
            </div>
            <div className='ttrroutes' style={{backgroundColor:props.bordercolor}}>
                <TTR_Routes/>
            </div>
            
            <div className='scores'>
                <div className='breakdown'>
                    <span>Track Score: {trackScore} </span>
                    {bonusSpan}
                    <button title='Reset' className='resetButton' onClick={() => resetPlayer()}>Reset</button>
                </div>
                
                <div className='totalScore'>
                    <textarea defaultValue='Player Name' />
                    <p>Total Score: {newTotal}</p>
                </div>
                
            </div>
            
        </div>
    );
}

export default Player;