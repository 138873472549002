const Track = ({trackName, trackCount, handleUpdateTrack}) => {

    

    return ( 
        <div className="track" title={trackName}>
            
            <span> {trackName}: {trackCount} </span> 
            <div className='buttons'>
                <button title='Minus' onClick={() => handleUpdateTrack(-1)}>-</button>
                <button title='Plus' onClick={() => handleUpdateTrack(1)}>+</button>
            </div>
        </div>
    );
}

export default Track;